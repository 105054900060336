var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajouter un nouveau groupe"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées du Groupe ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Année Univ","label-for":"Année Univ"}},[_c('validation-provider',{attrs:{"name":"Année Univ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":"","placeholder":"Année Univ"},model:{value:(_vm.groups.academic_year),callback:function ($$v) {_vm.$set(_vm.groups, "academic_year", $$v)},expression:"groups.academic_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Code du group","label-for":"Code du group"}},[_c('validation-provider',{attrs:{"name":"Code du group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Code du group"},model:{value:(_vm.groups.group_code),callback:function ($$v) {_vm.$set(_vm.groups, "group_code", $$v)},expression:"groups.group_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Parcour","label-for":"Parcour"}},[_c('validation-provider',{attrs:{"name":"Parcour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.parcours,"label":"title","placeholder":"Parcour"},model:{value:(_vm.groups.parcour),callback:function ($$v) {_vm.$set(_vm.groups, "parcour", $$v)},expression:"groups.parcour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Niveau","label-for":"Niveau"}},[_c('validation-provider',{attrs:{"name":"Niveau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Niveau"},model:{value:(_vm.groups.level),callback:function ($$v) {_vm.$set(_vm.groups, "level", $$v)},expression:"groups.level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('h5',[_vm._v("Groupe Arabe")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},on:{"click":function($event){_vm.groups.arab_group = true}},model:{value:(_vm.groups.arab_group),callback:function ($$v) {_vm.$set(_vm.groups, "arab_group", $$v)},expression:"groups.arab_group"}},[_vm._v(" Groupe Arabe ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addGroup.apply(null, arguments)}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }